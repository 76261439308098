<template>
  <div class="v-application table" v-if="items.length">
    <v-data-table
        :headers="columns"
        :mobile-breakpoint="0"
        :items="items"
        :item-key=" addUrl.indexOf('adduser') >= 0 ? 'contractId' : 'id' "
        class="elevation-1"
        :search="searchQuery"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :must-sort="true"
        @page-count="$emit('change', $event)"
    >
      <template slot="no-data">
        <div></div>
      </template>

      <template v-slot:item.system="{ item }">
        <div class="table_n">

          <template v-if="!item.superadmin">
            <div v-for="(role, ind) in item.roles" :key="ind" class="table-row" >
            <span class="table-cell __service">
               {{ role.service }}
            </span>
            <span class="table-cell __role">
            {{ role.roleName }}
            </span>

            </div>
          </template>


          <div class="table-row" v-else>
            <span class="table-cell __service">
            </span>
            <span class="table-cell __role">
              Суперадмин

            </span>

            <span class="table-cell">

            </span>

          </div>
        </div>

      </template>


      <template v-slot:item.role="{ item }" class="px-0">
<!--            <span v-for="(role, ind) in item.roles" :key="ind" class="mt">-->
<!--              {{ role.roleName }}-->
<!--            </span>-->
      </template>


          <template v-slot:item.status="{ item }">
            <span class="last">
              <span class="ellipsis" @click="setEmployersId(item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="5" viewBox="0 0 25 5">
                  <g fill="none" fill-rule="evenodd">
                      <g fill="#818C99">
                          <path d="M1311.5 332c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm10 0c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm10 0c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z" transform="translate(-1309 -332)"/>
                      </g>
                  </g>
                </svg>
              </span>

              <div class="more" v-if="moreId == item.contactId && filialID == item.filialId" v-click-outside="onClickOutside">
                <ul >
                  <li v-if="!item.superadmin">
                     <router-link :to="addUrl + moreId">Редактировать</router-link>
                  </li>
                  <li>
                    <a href="#" @click.prevent="deleteById(moreId, filialID, item.superadmin)">Удалить</a>
                  </li>
                  </ul>
              </div>
            </span>
          </template>


            <template v-slot:item.filialId="{ item }" >
              <span class="last">
                <span class="ellipsis" @click="moreId = moreId == item.id ? -1 : item.id">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="5" viewBox="0 0 25 5">
                    <g fill="none" fill-rule="evenodd">
                        <g fill="#818C99">
                            <path d="M1311.5 332c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm10 0c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm10 0c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z" transform="translate(-1309 -332)"/>
                        </g>
                    </g>
                  </svg>
                </span>

                <div class="more" v-if="moreId == item.id" v-click-outside="onClickOutside">
                  <ul >
                    <li>
                       <router-link :to="addUrl + moreId">Редактировать</router-link>
                    </li>
                    <li>
                      <a href="#" @click.prevent="deleteById(moreId)">Удалить</a>
                    </li>
                    </ul>
                </div>
              </span>
            </template>



    </v-data-table>

        <div class="text-center pt">
          <v-pagination
              @input="$emit('changePage', $event)"
              :value="page"
              :length="pageCount"
              v-if="pageCount > 1"
          ></v-pagination>

          <div class="input-group select">
            <label for="inputId"> Показать </label>
            <select name="filial" id="inputId" @change="$emit('changeItems', $event)">
              <option selected value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div>

    </div>
  </div>
</template>

<script>

export default {
name: "VuetifyDataTable",
  data() {
    return {
      moreId: -1,
      filialID: -1,
      requested: false
    }
  },
  props: {
    searchQuery: {
      default:'',
    },
    addUrl: {
      default: ''
    },
    page: {
      default: 1,
    },
    pageCount: {
      default: 0,
    },
    itemsPerPage: {
      default: 10,
    },
    columns: {},
    items: {}
  },
  methods: {
    setEmployersId(item){
      if(this.moreId == item.contactId && this.filialID == item.filialId) {
        this.moreId = -1;
        this.filialID = -1;
      }
      else {
        this.moreId = item.contactId;
        this.filialID = item.filialId;
      }
    },
    deleteById(id, filial, superadmin) {
      if(this.requested)
        return;

      this.requested = true;

      if(this.addUrl.indexOf("adduser") >= 0) {
          this.$store.dispatch('deleteUser', {id: parseInt(id), filial: filial, superadmin: (superadmin  ? 1 : 0)}).then((response) => {
            this.requested = false;
          } );

      }
      else
        this.$store.dispatch('deleteFilial', id).then((response) => {
          this.requested = false;
        } );
    },
    onClickOutside () {
      this.moreId = -1
    }
  }

}
</script>

<style lang="scss">

.v-data-table__wrapper{
  @include md-max {
    overflow-x: auto;
    overflow-y: auto;
  }
}

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-collapse: collapse;
  overflow-x: auto;
  overflow-y: hidden;

  tfoot:not(.previousPage:before),  tfoot:not(.nextPage:before){
    display: none;
  }


  .previousPage {
    position: relative;
  }
  .previousPage:before {
    content: "\f104";
    font-family: "Font Awesome 5 Free";
    font-weight:900;
    padding: 18px;
  }

  .nextPage {
    position: relative;
  }
  .nextPage:before {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    padding: 18px;
    font-weight:900;
    left: 5px;
  }

  thead {
    background-color: $bg-light;

    .arrow {
      cursor: pointer;
    }
    .arrow.asc {
      position: relative;
    }
    .arrow.asc:before {
      content: "\f062";
      font-family: "Font Awesome 5 Free";
      font-weight:900;
      position: absolute;
      left: 5px;
    }

    .arrow.dsc {
      position: relative;
    }
    .arrow.dsc:before {
      content: "\f063";
      font-family: "Font Awesome 5 Free";
      position: absolute;
      left: 5px;
      font-weight:900;
    }

    th {
      padding: 10px;
      padding-right: 30px;
      color: $text-gray;
      font-weight: normal;
      vertical-align: middle;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;

    }
    th:not(:first-of-type) {
      padding-left: 0px;
    }
  }

  tbody {
    tr {
      border-bottom: 2px solid $light-border;
    }
    td {
      padding-top: 32px;
      padding-bottom: 23px;
      padding-right: 15px;
      vertical-align: top;
      &:first-child {
        width: 250px;
      }
    }
    td:not(:first-of-type) {
      color: $text-gray;
    }

    .table_n {
      display: table;
      .table-row {
        display: table-row;
      }

      .table-cell {
        display: table-cell;
        padding-bottom: 20px;
        width: 130px;
        &.__service {
          width: 150px;
          min-width: 150px;
        }
        &.__role {
          padding-left: 30px;
        }
      }
    }

    .mt {
      display: block;
      margin-bottom: 23px;
      border: none;
      td {
        padding: 0px;
        text-align: left !important;
      }
    }

    .mt td:first-of-type {
      min-width: 67px;
    }

    .mt td:not(:first-of-type) {
      margin-left: 10px;
    }
    .mt:last-of-type {
      margin-bottom: 0px;
    }

    .last {
      display: flex;
      align-items: center;
      position: relative;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }

    td:last-of-type {
      vertical-align: middle;
      padding-right: 0px;
      text-align: center;

      .more {
        position: absolute;
        top: 45px;
        right: 0px;
        z-index: 9;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(3, 25, 51, 0.13);
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          text-align: left;
          font-size: 16px;
          li {
            padding: 16px;
            border-bottom: 1px solid $light-border;
          }

          li:last-of-type a {
            color: $error-light;
          }
        }
      }
    }


    .icon {
      display: grid;
      margin: 0 25px;
      align-items: center;
      border-radius: 20px;
      text-align: center;
      color: #fff;
      height: 24px;
      width: 24px;
      right: 20px;
    }
    .icon:before {
      display: none;
    }
    .icon.connected {
      background-color: $success-2;
    }

    .icon.notConnected {
      background-color: $error;
    }

    .icon.check {
      background-color: $check;
    }

    .icon.connected:after {
      content: '\2713';
    }

    .icon.notConnected:after {
      content: '!';
    }

    .icon.check:after {
      content: '..';
    }

    .ellipsis {
      cursor: pointer;
      vertical-align: middle;
      align-items: center;
      display: grid;
      padding: 20px 0;
    }
  }
}


@include md-max {
  table {
    font-size: 14px;

    tbody {
      td {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.pt {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.v-application.table {
  display: block;
  margin-top: 36px;




  .v-icon {
    align-items: center;
    display: inline-flex;
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
    font-size: 24px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    text-indent: 0;
    transition: .3s cubic-bezier(.25,.8,.5,1),visibility 0s;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .v-icon {
    color: rgba(0,0,0,.54);
    font-style: normal;
  }




  .select {
    label {
      color: $text-gray;
    }

    select {
      font-size: 18px;
      padding: 4px 10px;
      border: none;
    }

    select:focus {
      outline: none;
    }

    select option:hover {
      background-color: $bg-light;
    }
  }

  thead {
    background-color: $bg-light;
    text-align: left;

    .sortable {
      cursor: pointer;
    }
    .sortable.asc {
      position: relative;
    }
    .sortable .v-icon:before {
      content: "\f062";
      font-family: "Font Awesome 5 Free";
      font-weight:900;
      position: absolute;
      left: 5px;
    }


    .sortable.asc .v-icon:before {
      content: "\f062";
      font-family: "Font Awesome 5 Free";
      font-weight:900;
      position: absolute;
      left: 5px;
    }

    .sortable.desc {
      position: relative;
    }
    .sortable.desc .v-icon:before {
      content: "\f063";
      font-family: "Font Awesome 5 Free";
      position: absolute;
      left: 5px;
      font-weight:900;
    }

    th {
      padding: 10px;
      padding-right: 30px;
      color: $text-gray;
      font-weight: normal;
      vertical-align: middle;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;

    }
    th:not(:first-of-type) {
      padding-left: 0px;
    }
  }


  .v-pagination{

    word-break: normal;
    tab-size: 4;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Roboto,sans-serif;
    line-height: 1.5;
    color: rgba(0,0,0,.87);
    text-align: center!important;
    background-repeat: no-repeat;
    box-sizing: inherit;
    padding: 0;
    align-items: center;
    display: inline-flex;
    list-style-type: none;
    justify-content: center;
    margin: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 0;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;


  }

  .v-pagination>li {
    align-items: center;
    display: flex;
  }


  .theme--light.v-pagination .v-pagination__item {
    background: #fff;
    color: rgba(0,0,0,.87)
  }

  .theme--light.v-pagination .v-pagination__item--active {
    color: #fff
  }

  .theme--light.v-pagination .v-pagination__navigation {
    background: #fff
  }

  .theme--dark.v-pagination .v-pagination__item {
    background: #1e1e1e;
    color: #fff
  }

  .theme--dark.v-pagination .v-pagination__item--active {
    color: #fff
  }

  .theme--dark.v-pagination .v-pagination__navigation {
    background: #1e1e1e
  }

  .v-pagination {
    align-items: center;
    display: inline-flex;
    list-style-type: none;
    justify-content: flex-end;
    margin: 0;
    max-width: 100%;
    width: 100%;



    button {
      cursor: pointer;
      color: inherit;
      -webkit-font-smoothing: antialiased;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      list-style-type: none;
      box-sizing: inherit;
      font: inherit;
      overflow: visible;
      text-transform: none;
      border-style: none;
      cursor: pointer;
      border-radius: 4px;
      font-size: 1rem;
      height: 42px;
      margin: .3rem;
      min-width: 34px;
      padding: 0 5px;
      text-decoration: none;
      transition: .3s cubic-bezier(0,0,.2,1);
      width: auto;
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    }

    button:focus {
      outline: none;
    }

  }

  .v-pagination.v-pagination {
    padding-left: 0
  }

  .v-pagination>li {
    align-items: center;
    display: flex
  }

  .v-pagination--circle .v-pagination__item,.v-pagination--circle .v-pagination__more,.v-pagination--circle .v-pagination__navigation {
    border-radius: 50%
  }

  .v-pagination--disabled {
    pointer-events: none;
    opacity: .6
  }

  .v-pagination__item {
    background: transparent;
    border-radius: 4px;
    font-size: 1rem;
    height: 42px;
    margin: .3rem;
    min-width: 34px;
    padding: 0 5px;
    text-decoration: none;
    transition: .3s cubic-bezier(0,0,.2,1);
    width: auto;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)
  }

  .v-pagination__item--active {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12)
  }

  .v-pagination__navigation {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 42px;
    width: 32px;
    margin: .3rem 10px
  }

  .v-pagination__navigation .v-icon {
    transition: .2s cubic-bezier(.4,0,.6,1);
    vertical-align: middle
  }

  .v-pagination__navigation--disabled {
    opacity: .6;
    pointer-events: none
  }

  .v-pagination__more {
    margin: .3rem;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    height: 32px;
    width: 32px
  }


  .mdi-chevron-left::before {
    content: "\f053";
    font-family: "Font Awesome 5 Free";
    font-weight:900;
  }

  .mdi-chevron-right::before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight:900;
  }


  .mdi:before, .mdi-set {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .v-icon:after {
    background-color: currentColor;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: scale(1.3);
    width: 100%;
    transition: opacity .2s cubic-bezier(.4,0,.6,1);
  }

.v-pagination__item{
  word-break: normal;
  tab-size: 4;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  list-style-type: none;
  box-sizing: inherit;
  font: inherit;
  overflow: visible;
  text-transform: none;
  border-style: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  height: 42px;
  margin: .3rem;
  min-width: 34px;
  padding: 0 5px;
  text-decoration: none;
  transition: .3s cubic-bezier(0,0,.2,1);
  width: auto;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
  background: #fff;
  color: rgba(0,0,0,.87);
}
}
</style>
